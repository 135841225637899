<template>
  <div>
    <b-card no-body class="mb-0">
      <b-card-body class="pb-0">
        <b-row>
          <b-col class="pb-0" cols="12">
            <b-row>
              <b-col cols="12" class="pb-0">
                <b-form-group>
                  <v-select
                    v-model="vspr_info.project"
                    :options="projects"
                    :placeholder="lang('t_project')"
                    :reduce="(val) => val.internal_name"
                    label="display_name"
                    :clearable="false"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" class="pb-0">
                <b-form-group>
                  <v-select
                    v-model="vspr_info.type"
                    :options="types"
                    label="value"
                    :reduce="(item) => item.key"
                    :clearable="false"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-body>

      <data-management
        :project="vspr_info.project"
        v-if="vspr_info.type == 'data_management'"
      ></data-management>
      <close-customer
        :project="vspr_info.project"
        v-if="vspr_info.type == 'close_customer'"
      ></close-customer>
      <close-product
        :project="vspr_info.project"
        v-if="vspr_info.type == 'close_product'"
      ></close-product>
      <open-customer
        :project="vspr_info.project"
        v-if="vspr_info.type == 'open_customer'"
      ></open-customer>
      <!-- <hitam-etme
                  :project="vspr_info.project"
                  v-if="vspr_info.type == 'hitam_etme'"
                ></hitam-etme> -->
      <product-management
        :project="vspr_info.project"
        v-if="vspr_info.type == 'product_management'"
      ></product-management>
      <note-import
        :project="vspr_info.project"
        v-if="vspr_info.type == 'note_import'"
      ></note-import>
      <delete-data
        :project="vspr_info.project"
        v-if="vspr_info.type == 'delete_data'"
      ></delete-data>
      <!-- <upload-file
                  :project="vspr_info.project"
                  v-if="vspr_info.type == 'upload_file'"
                ></upload-file> -->
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText, BLink, BRow, BCol, BFormGroup, BCardBody } from 'bootstrap-vue'
import vSelect from 'vue-select'

import DataManagement from '@core/components/VSPR/DataManagement.vue';
import CloseCustomer from '@core/components/VSPR/CloseCustomer.vue';
import CloseProduct from '@core/components/VSPR/CloseProduct.vue';
import OpenCustomer from '@core/components/VSPR/OpenCustomer.vue';
// import HitamEtme from '@core/components/VSPR/HitamEtme.vue';
import NoteImport from '@core/components/VSPR/NoteImport.vue';
import ProductManagement from '@core/components/VSPR/ProductManagement.vue';
import UploadFile from '@core/components/VSPR/UploadFile.vue';
import DeleteData from '@core/components/VSPR/DeleteData.vue';


export default {
  components: {
    BCardBody,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    DataManagement,
    CloseCustomer,
    CloseProduct,
    OpenCustomer,
    ProductManagement,
    // HitamEtme,
    NoteImport,
    UploadFile,
    vSelect,
    BFormGroup,
    DeleteData,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {

      lang: _l(window.localStorage.getItem('language') || "tr"),
      vspr_info: {
        project: '',
        type: 'data_management',
      },
      projects: [],
      types: [
        {
          key: 'data_management',
          value: globalThis._lang("t_dataManagement"),
        },
        {
          key: 'delete_data',
          value: globalThis._lang("t_deleteData"),
        },
        {
          key: 'product_management',
          value: globalThis._lang("t_productManagement"),
        },
        // {
        //   key: 'upload_file',
        //   value: globalThis._lang("t_fileUpload"),
        // },
        {
          key: 'close_customer',
          value: globalThis._lang("t_deactiveCustomer"),
        },
        {
          key: 'close_product',
          value: globalThis._lang("t_closeProduct"),
        },
        // {
        //   key: 'open_customer',
        //   value: globalThis._lang("t_activeCustomer"),
        // },
        // {
        //   key: 'assign_manager',
        //   value: globalThis._lang("t_assigneManager"),
        // },
        // {
        //   key: 'hitam_etme',
        //   value: globalThis._lang("t_conclude"),
        // },
        // {
        //   key: 'note_import',
        //   value: globalThis._lang("t_addingNote"),
        // },
        // {
        //   key: 'remove_voice',
        //   value: globalThis._lang("t_deleteCallRecord"),
        // },
      ],
    }
  },
  methods: {
    GetProjects: async function () {
      var response = (
        await this.$http_in.get(`agent/v1/Project`)
      ).data;

      this.projects = response;
    },
  },
  mounted: async function () {
    await this.GetProjects();
    this.vspr_info.project = globalThis.user.selected_project;
  }
}

</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

